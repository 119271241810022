@import '../../foundation/variables';

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid;

  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-title {
    font-size: 1rem;
  }

  .header-buttons {
    position: relative;
    display: flex;
    column-gap: 1.5rem;
    a {
      text-decoration: none;
      color: var(--text-color);
    }
  }

  .iconButton {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: var(--text-color);
    transition: color 0.3s;
  }

  .dropdownMenu {
    position: absolute;
    width: 7rem;
    top: 100%;
    right: 0;
    background-color: var(--background-color);
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000;
  }

  .dropdownItem {
    display: block;
    width: 100%;
    padding: 10px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    color: var(--text-color);
    transition: background-color 0.3s;
  }

  .dropdownItem:hover {
    background-color: #f1f1f1;
  }
}

/* ライトモードのスタイル */
body[data-theme='light'] {
  --background-color: #cdcdee;
  --text-color: #454058;
  --border-color: #454058;
}

/* ダークモードのスタイル */
body[data-theme='dark'] {
  --background-color: #000000;
  --text-color: #b4ad9f;
  --border-color: #b4ad9f;
}