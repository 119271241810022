.obs-title {
  text-align: center;
}

.container-times {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color, #000); /* デフォルトは黒 */
  color: var(--text-color, #fff); /* デフォルトは白 */
}

#first-time, #second-time {
  margin-top: 0;
}

.container-customs {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.container-customs-font-size ul {
  list-style: none;
  padding: 0;
}

.container-customs-font-size li {
  margin-bottom: 10px;
  input[type="radio"] {
    margin-left: 10px;
  }
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}