@import 'ress'; // ここで ress をインポート
@import './foundation/variables';
@import './object/component/_header';
@import './object/component/_main';
@import './object/component/_footer';

body {
  font-size: $base-font-size;
  font-family: $base-font-family;
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--border-color);
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header, .main, .footer {
  flex: 1;
}