main {
  max-width: 1536px;
  margin: 3rem auto;

  h2 {
    text-align: center;
  }

  .container-times {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 2rem;
  
    p {
      font-size: 2rem;
    }
  }
}
