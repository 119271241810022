@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/fonts/Bender.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// ライトモード用カラー
$light-background-color: #cdcdee;
$light-text-color: #454058;
$light-border-color: #454058;

// ダークモード用カラー
$dark-background-color: #000000;
$dark-text-color: #b4ad9f;
$dark-border-color: #b4ad9f;

// フォントサイズ
$base-font-size: 1rem;
$base-font-family: 'CustomFont', sans-serif;